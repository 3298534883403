.surface{
    background-color:#dfe2ef ;
    
    margin-top: 10;
    color: black;
    margin: 10px;
    padding: 10px;


}

.fc-col-header-cell-cushion{
    color: black;
}
.fc-daygrid-day-number{
    color: black;
}

.fc-h-event {background-color: #283465;}

.fc-today-button.fc-button.fc-button-primary{
    background-color: #283465;
    color:#d6c369;
}

.fc-today-button.fc-button.fc-button-primary:disabled{
    background-color: #283465;
    color:#d6c369;
}

.fc-today-button.fc-button.fc-button-primary:disabled{
    opacity:1;
}

.fc-prev-button.fc-button.fc-button-primary{
    background-color: #283465;
}

.fc-next-button.fc-button.fc-button-primary{
    background-color: #283465;
}
.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active{
    background-color: #283465;
}
