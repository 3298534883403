
.react-datepickerDark{
    background-color: #475360;
    box-shadow: 2px 2px 10px #000000;
}

.react-datepickerDark .react-datepicker__week{
    background-color: #475360;
    color: #ffffff44;

}

.react-datepickerDark .react-datepicker__header {
    background-color: #475360;
}

.react-datepickerDark .react-datepicker__day{
    background-color: #475360;
    color: #ffffffaa;
}

.react-datepickerDark .react-datepicker__day:hover{
    background-color: #00000044;
}

.react-datepickerDark .react-datepicker__month-container{
    background-color: #313943;
}

.react-datepickerDark .react-datepicker__day-name{
    color:#FFFFFFaa;      

}
.react-datepickerDark .react-datepicker__current-month{
    color:#FFFFFFaa;      

}




.react-datepickerLight{
    background-color: #ffffff;
    box-shadow: 2px 2px 10px #000000;
}

.react-datepickerLight .react-datepicker__week{
    background-color: #ffffff;
    color: #000000;

}

.react-datepickerLight .react-datepicker__header {
    background-color: #ffffff;
}

.react-datepickerLight .react-datepicker__day{
    background-color: #ffffff;
    color: #000000;
}

.react-datepickerLight .react-datepicker__day:hover{
    background-color: #FFFFFF44;
}

.react-datepickerLight .react-datepicker__month-container{
    background-color: #ffffff;
}

.react-datepickerLight .react-datepicker__day-name{
    color:#000000aa;      

}
.react-datepickerLight .react-datepicker__current-month{
    color:#000000aa;      

}
