.mobileTable{
   background-color: 'red';
   min-height: 80vh;
   max-height: 80vh;
   overflow-y: auto;
}

.mobileTableCell{
    box-shadow:3px 3px 10px #44444433;
    margin:10px;
    padding:20px;
    min-width:400px;
    max-width:400px;
    background-color:#FFFFFF;
    border-radius: 20px;
    
}

.mobileTableFilter{
    
   
    
    
}

.mobileTableCellFooter{
    
}