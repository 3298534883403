.App {
  text-align: center;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.pagingToolbar{
    display: flex; 
    background-color: #313943;
    min-height: 60px;
    align-items: center;
    color:#FFFFFF44;
    font-size: 18px;


}

.pagingToolbarText{
    background-color: #313943;
    align-items: center;
    color:#FFFFFF44;
    font-size: 14px;
    padding-left: 10px;
    padding-bottom: 3px;
}


.pagingToolbarButtonDark{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #313943;
    border-radius: 3px;
    cursor:pointer;
}

.pagingToolbarButtonDark:hover{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #475360;
    border-radius: 3px;
    cursor:pointer;
}

.pagingToolbarButtonLight{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #FFFFFF;
    border-radius: 3px;
    cursor:pointer;
}

.pagingToolbarButtonLight:hover{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #AAAAAA;
    border-radius: 3px;
    cursor:pointer;
}



.pagingToolbarIcon{
    min-height: 30px;
}

.pagingToolbarSpinnerDark{

    font-size: 16px;
    margin-left: 20px;
    background-color: #00000044;
    color:#ffffff44;
    max-width: 60px;
}
.pagingToolbarSpinnerLight{

    font-size: 16px;
    margin-left: 20px;
    background-color: #FFFFFF;
    color:#000000aa;
    max-width: 60px;
}


select option {
    margin: 40px;
    background: #ffffff;
    color: #000000;
    font-size: 14px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.nav-tabs .nav-link{
    color:#9ba7b4;
}