

.speech-bubble {
    color:black;
    text-align:left;
    position: relative;
    background: #778899;
    display: inline-block;
    width: 90%;
    min-height:'fitt-content';
    margin: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    top: 5px;
    left: -20px;
    width: 0;
    height: 0;
    border-style: inset;
    border-width: 0 30px 15px 0;
    border-color: transparent #778899 transparent transparent;
    -webkit-transform:rotate(360deg);
    -moz-transform:rotate(360deg);
    transform:rotate(360deg);
}