body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.pagingToolbar{
    display: flex; 
    background-color: #313943;
    min-height: 60px;
    align-items: center;
    color:#FFFFFF44;
    font-size: 18px;


}

.pagingToolbarText{
    background-color: #313943;
    align-items: center;
    color:#FFFFFF44;
    font-size: 14px;
    padding-left: 10px;
    padding-bottom: 3px;
}


.pagingToolbarButtonDark{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #313943;
    border-radius: 3px;
    cursor:pointer;
}

.pagingToolbarButtonDark:hover{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #475360;
    border-radius: 3px;
    cursor:pointer;
}

.pagingToolbarButtonLight{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #FFFFFF;
    border-radius: 3px;
    cursor:pointer;
}

.pagingToolbarButtonLight:hover{
    min-height: 35px;
    margin-left: 10px;
    border:solid 1px #AAAAAA;
    border-radius: 3px;
    cursor:pointer;
}



.pagingToolbarIcon{
    min-height: 30px;
}

.pagingToolbarSpinnerDark{

    font-size: 16px;
    margin-left: 20px;
    background-color: #00000044;
    color:#ffffff44;
    max-width: 60px;
}
.pagingToolbarSpinnerLight{

    font-size: 16px;
    margin-left: 20px;
    background-color: #FFFFFF;
    color:#000000aa;
    max-width: 60px;
}


select option {
    margin: 40px;
    background: #ffffff;
    color: #000000;
    font-size: 14px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.nav-tabs .nav-link{
    color:#9ba7b4;
}
.loginWindow{
    background-color: white; 
    min-height: 100%; 
    min-width: 100%; 
    position: absolute; 
    display: flex;
    background-color: #FFFFFF;
}

.loginBox{
    margin: auto;
    width: 400px;
    height: 560px;
    background-color: white;
    display: block;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 8px #00000044;
    padding: 10px;
    
}

.loginHeader{
    background-color: #FFFFFF;
    color:black;
    width: 100%;
    min-height: 30px;
    display: block;
    margin: auto;
    display: flex;
    width: 100%; 
    text-align: center; 
    justify-content: center;
    margin-top: 0px;
}

.logoStyle{
    max-width: 60%;
    text-align: center;
    margin:auto;
}

.loginHeaderText{
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 12px;
    line-height: 18px;
    font-family: Roboto, sans-serif;
    font-size-adjust: none;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-variant-alternates: normal;
    font-variant-east-asian: normal;
    -webkit-font-variant-ligatures: normal;
            font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    -webkit-font-language-override: normal;
            font-language-override: normal;
    -webkit-font-feature-settings: normal, auto, normal, normal, "subs" off, "sups" off;
            font-feature-settings: normal, auto, normal, normal, "subs" off, "sups" off;
    font-variation-settings: normal;
    color:#000000aa;
}

.loginRow{
    display: flex ;
    margin-top: 50px;
    justify-content: center;
}

.loginTitle{
    text-align: left;  
    margin-left: 30px;
    min-width:140px;
    color: #FFFFFFaa;
    font-weight: bold;
}

.loginInput{
    display: flex;
    color: inherit;
    min-height: 16px;
    text-align: center;

}

.loginInput{
    display: flex;
    color: inherit;
    min-height: 16px;
    text-align: center;
    border-bottom: #4c8ec4;
}

.loginTextField{
    background-color: transparent;
    border:transparent;
    color: inherit;
    outline: none;
    border-bottom:solid 1px #00000044;
    min-width: 350px;
}

.loginTextField:focus{
    
    border-bottom:solid 1px #4c8ec4 ;
    
}


.loginButton{
    background-color: #4c8ec4;
    color:white;
    padding-left:  50px;
    padding-right:   50px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
    font-size: 16px;
    
    text-align: center;
    cursor: pointer;
    border-color: transparent;
}

.loginButton:hover{
    background-color: #325d80;
    
}

.forgottPassword{
    text-align: center;
    color:#4c8ec4;
    font-size: 13px;
}

.loginSuccess{
    display: none; 
    width: 100%; 
    text-align: center; 
    justify-content: center;
    background-color: white ;
    color: #FF0000;
    font-size: 12px;
    padding: 10px;
    text-shadow: 1px 1px 3px #000000;

}

.loginFail{
    display: flex; 
    width: 100%; 
    text-align: center; 
    justify-content: center;
    background-color: white ;
    color: #FF0000aa;
    font-size: 14px;
    padding: 10px;


}

.menu{
    margin-top: 100px;
    min-width: 15%;
    height: 100%;

}

.menuCollapsed{
    margin-top: 100px;
    min-width: 3%;
    height: 100%;


}

.contentDark{
    min-width: 85%;
    background-color: #475360;
    overflow-y: scroll;


}
.contentLight{
    min-width: 85%;
    background-color: #ebedf7;
    overflow-y: scroll;


}

.contentMenuCollapsedDark{
    margin-top: 100px;
    min-width: 97%;
    background-color: #475360;
}
.contentMenuCollapsedLight{
    margin-top: 100px;
    min-width: 97%;
    background-color: #ebedf7;
}

.menuItem{
    display: flex;
    align-items: center;


    background-color: #adb3b8 ;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    color: #475360;
    font-size: 14px;
    line-height: 64px;
    cursor:pointer;
}

.menuItem:hover{

    background-color:  #adb3b8;
    color:#ffffff;

}


.fadeOut{
    opacity:1;
    width:0;
    height:0;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    -webkit-transform: scale(0);
            transform: scale(0);
}

.fadeIn{
    opacity:1;
    width:'100%';
    height:'100%';
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    -webkit-transform: scale(1);
            transform: scale(1);

}

.menuItemTextDark{
    color: #adb3b8;
    margin-left: 10px;
    font-size: 14px;
    line-height: 64px;
    cursor:pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}
.menuItemTextLight{
    color: #555E68;
    margin-left: 10px;
    font-size: 14px;
    line-height: 64px;
    cursor:pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.menuAndContentHeader{
    position: fixed;
    top:50px;
    display: flex;
    width: 100%;
}
.menuHeader{
    min-width: 15%;
    min-height: 50px;
    background-color: #9FD027;
    color:white;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px #00000077;
}

.menuHeaderCollapsed{
    min-width: 3%;
    min-height: 50px;
    background-color: #9FD027;
    color:white;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px #00000077;
}


.menuHeaderText{
    margin-left: 10px;
}

.contentHeader{
    min-width: 85%;
    min-height: 50px;
    box-shadow: 2px 2px 4px #00000077;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:white;
    z-index: 1000;
    position: relative;

}
.contentHeaderMenuCollapsed{
    min-width: 97%;
    min-height: 50px;
    box-shadow: 2px 2px 4px #00000077;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:white;
    z-index: 1000;
    position: relative;

}

.partnerSelect{
    font-size: 16px;
    margin-left: 20px;
    background-color: #ffffff;
    color: #000000aa;
    margin: 10px;
    border: solid 1px #ffffff;
}

.popupFrameLight{
    position: fixed;
    display: flex;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(256,256,256,0.5);
    z-index: 1001;
    overflow-y: auto;
}

.formLight{
    display: flex;
    color:#000000;
    padding: 10px;
    margin: auto;

    width: 760px;
    height: 90%;
    background-color: white;
    display: block;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 8px #000000;
    border-radius: 5px;
    overflow-y: auto;
    white-space: nowrap;

    -webkit-user-select: none; /* Safari */ /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

}
.popupWrapperLight{
    display: flex;
    height: 100%;
    flex-direction: column;
}
.popupHeaderLight{
    text-align: center;
    background-color:white;
    color: #313943;
    font-weight: bold;
    padding: 5px;
    text-transform: uppercase;
}

.popupBodyLight{
    flex:1 1;
    overflow-y: auto;
    text-align: center;
}


.popupFooterLight{
    text-align: center;
    margin: 20px;
    justify-content: center;
}

.row{
    margin-top: 10px;
}

.dropdown-listLight{
    background:#e9ecef;
    position:absolute;
    display: inline-block;
    left:0px;
    top:30px;
    z-index:1;
    width:100%;
    min-width:-webkit-fit-content;
    min-width:-moz-fit-content;
    min-width:fit-content;
    text-align: left;
    margin:0px;
    border: solid 1px #e9ecef;
    box-shadow: 0px 6px 8px 3px rgba(0,0,0,0.18);
}

.dropdown-list-itemLight{
    cursor:pointer;
    width:100%;
    text-align: left;
    padding:5px;
    border-bottom: solid 1px #cccccc;
    z-index: 2;
}

.thumbnailLight{
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 5px; /* Some padding */
    width: 365px; /* Set a small width */
}

/* Add a hover effect (blue shadow) */
.thumbnailLight:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

button, input, optgroup, select,number, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px #31394330;
   
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dialogHeaderLight{
    text-align: center;
    color: #313943;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.componentLight{
    background-color: #FFFFFF;
    color:#25323b;
}

.contentLight{
    background-color: #FFFFFF;
    padding-top: 15px;
    overflow: hidden ;
    max-width: 99%;
}

.contentHeaderLight{
    background-color: #FFFFFF;
}


.popupFrameDark{
    position: fixed;
    display: flex;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #25323b77;
    z-index: 1001;
    overflow-y: auto;
}

.formDark{
    display: flex;
    color:#9ba7b4;
    padding: 10px;
    margin: auto;

    width: 760px;
    height: 90%;
    background-color: #25323b;
    display: block;
    box-shadow: 0px 0px 8px #000000;
    border-radius: 5px;
    overflow-y: auto;
    white-space: nowrap;

    -webkit-user-select: none; /* Safari */ /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

}
.popupWrapperDark{
    display: flex;
    height: 100%;
    flex-direction: column;
}
.popupHeaderDark{
    text-align: center;
    background-color:#25323b;
    color: #9ba7b4;
    font-weight: bold;
    padding: 5px;
    text-transform: uppercase;
}

.popupBodyDark{
    flex:1 1;
    overflow-y: auto;
    text-align: center;
  
}


.popupFooterDark{
    text-align: center;
    margin: 20px;
    justify-content: center;
}

.row{
    margin-top: 10px;
}

.dropdown-listDark{
    background:#25323b;
    position:absolute;
    display: inline-block;
    left:0px;
    top:30px;
    z-index:1;
    width:100%;
    min-width:-webkit-fit-content;
    min-width:-moz-fit-content;
    min-width:fit-content;
    text-align: left;
    margin:0px;
    border: solid 1px #31394330;
    box-shadow: 0px 6px 8px 3px rgba(0,0,0,0.18);
}

.dropdown-list-itemDark{
    cursor:pointer;
    width:100%;
    text-align: left;
    padding:5px;
    border-bottom: solid 1px #313943ff;
    z-index: 2;
}

.thumbnailDark{
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 5px; /* Some padding */
    width: 365px; /* Set a small width */
}

/* Add a hover effect (blue shadow) */
.thumbnailDark:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

button, input, optgroup, select,number, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px black;
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dialogHeaderDark{
    text-align: center;
    color: #9ba7b4;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.componentDark{
    background-color: #25323b;
    color:#9ba7b4;
    border-bottom:solid 1px #ddd0d00d;
    
}

.contentDark{
    background-color: #313943;
    padding-top: 15px;
    overflow: hidden;
    max-width: 99%;
}

.contentHeaderDark{
    background-color: #313943;
}


.dateContainerDark{
    color:#9ba7b4;
    display: flex;
    outline: none;
    
    font-size:12px;
    border-bottom: solid 1px #ddd0d00d;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #25323b;

}
.dateContainerFocusedDark{
    color:#9ba7b4;
    display: flex;
    border-bottom: solid 1px #ddd0d00d;;
    outline: none;
    font-size:12px;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #25323b;
}
.dateContainerLight{
    
    display: flex;
    outline: none;
    font-size:12px;
  
    border-bottom: solid 1px black;
    border-radius: 3px;
    
    box-sizing: border-box;
    background-color: #FFFFFF;

}
.dateContainerFocusedLight{
    display: flex;
    border-bottom: solid 1px black;
    outline: none;
    font-size:12px;
  
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    
}

.dateInput{
    outline: none;
    font-size:12px;
    border: none;
    padding: 6px 6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    
}

.dateInput:focus{
    outline: none;
    border: none;
    height: 100%;

        
}

.dateDot{
    color: red;
    font-size: 20px;
    max-height: 12px;
    margin-bottom: 10px;
    border:'solid 1px red';
    background: red;
    
}

.yearInput{
    outline: none;
    border: none;
    padding-left: 6px;
    padding-right: 0px;
    padding-top:6px;
    padding-bottom:6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    width:5ch;
    font-size: 1em;

}
.monthInput{
    outline: none;
    
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-top:6px;
    padding-bottom:6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    width:2ch;
    font-size: 1em;
}

.dayInput{
    outline: none;
    

    border: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-top:6px;
    padding-bottom:6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    width:2ch;
    font-size: 1em;
}


.react-datepickerDark{
    background-color: #475360;
    box-shadow: 2px 2px 10px #000000;
}

.react-datepickerDark .react-datepicker__week{
    background-color: #475360;
    color: #ffffff44;

}

.react-datepickerDark .react-datepicker__header {
    background-color: #475360;
}

.react-datepickerDark .react-datepicker__day{
    background-color: #475360;
    color: #ffffffaa;
}

.react-datepickerDark .react-datepicker__day:hover{
    background-color: #00000044;
}

.react-datepickerDark .react-datepicker__month-container{
    background-color: #313943;
}

.react-datepickerDark .react-datepicker__day-name{
    color:#FFFFFFaa;      

}
.react-datepickerDark .react-datepicker__current-month{
    color:#FFFFFFaa;      

}




.react-datepickerLight{
    background-color: #ffffff;
    box-shadow: 2px 2px 10px #000000;
}

.react-datepickerLight .react-datepicker__week{
    background-color: #ffffff;
    color: #000000;

}

.react-datepickerLight .react-datepicker__header {
    background-color: #ffffff;
}

.react-datepickerLight .react-datepicker__day{
    background-color: #ffffff;
    color: #000000;
}

.react-datepickerLight .react-datepicker__day:hover{
    background-color: #FFFFFF44;
}

.react-datepickerLight .react-datepicker__month-container{
    background-color: #ffffff;
}

.react-datepickerLight .react-datepicker__day-name{
    color:#000000aa;      

}
.react-datepickerLight .react-datepicker__current-month{
    color:#000000aa;      

}



.speech-bubble {
    color:black;
    text-align:left;
    position: relative;
    background: #778899;
    display: inline-block;
    width: 90%;
    min-height:'fitt-content';
    margin: 15px;
    border-radius: 10px;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    top: 5px;
    left: -20px;
    width: 0;
    height: 0;
    border-style: inset;
    border-width: 0 30px 15px 0;
    border-color: transparent #778899 transparent transparent;
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
  pointer-events: none;
}
.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  margin: 0;
  padding: 0;
}
.ql-editor {
  box-sizing: border-box;
  cursor: text;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li::before {
  content: '\2022';
}
.ql-editor ul[data-checked=true],
.ql-editor ul[data-checked=false] {
  pointer-events: none;
}
.ql-editor ul[data-checked=true] > li *,
.ql-editor ul[data-checked=false] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before,
.ql-editor ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before {
  content: '\2611';
}
.ql-editor ul[data-checked=false] > li::before {
  content: '\2610';
}
.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
  text-align: right;
  margin-right: 0.3em;
  margin-left: -1.5em;
}
.ql-editor li.ql-direction-rtl::before {
  text-align: left;
  margin-left: 0.3em;
}
.ql-editor ol li,
.ql-editor ul li {
  padding-left: 1.5em;
}
.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-num;
}
.ql-editor ol li:before {
  content: counter(list-num, decimal) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: #000;
}
.ql-editor .ql-bg-red {
  background-color: #e60000;
}
.ql-editor .ql-bg-orange {
  background-color: #f90;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}
.ql-editor .ql-bg-green {
  background-color: #008a00;
}
.ql-editor .ql-bg-blue {
  background-color: #06c;
}
.ql-editor .ql-bg-purple {
  background-color: #93f;
}
.ql-editor .ql-color-white {
  color: #fff;
}
.ql-editor .ql-color-red {
  color: #e60000;
}
.ql-editor .ql-color-orange {
  color: #f90;
}
.ql-editor .ql-color-yellow {
  color: #ff0;
}
.ql-editor .ql-color-green {
  color: #008a00;
}
.ql-editor .ql-color-blue {
  color: #06c;
}
.ql-editor .ql-color-purple {
  color: #93f;
}
.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  pointer-events: none;
  position: absolute;
}
.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: '';
  display: table;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}
.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}
.ql-snow.ql-toolbar button:active:hover,
.ql-snow .ql-toolbar button:active:hover {
  outline: none;
}
.ql-snow.ql-toolbar input.ql-image[type=file],
.ql-snow .ql-toolbar input.ql-image[type=file] {
  display: none;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}
@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active),
  .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}
.ql-snow {
  box-sizing: border-box;
}
.ql-snow * {
  box-sizing: border-box;
}
.ql-snow .ql-hidden {
  display: none;
}
.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden;
}
.ql-snow .ql-tooltip {
  position: absolute;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}
.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}
.ql-snow .ql-tooltip.ql-flip {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-formats:after {
  clear: both;
  content: '';
  display: table;
}
.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444;
}
.ql-snow .ql-empty {
  fill: none;
}
.ql-snow .ql-even {
  fill-rule: evenodd;
}
.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1;
}
.ql-snow .ql-transparent {
  opacity: 0.4;
}
.ql-snow .ql-direction svg:last-child {
  display: none;
}
.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}
.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}
.ql-snow .ql-editor h1 {
  font-size: 2em;
}
.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}
.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}
.ql-snow .ql-editor h4 {
  font-size: 1em;
}
.ql-snow .ql-editor h5 {
  font-size: 0.83em;
}
.ql-snow .ql-editor h6 {
  font-size: 0.67em;
}
.ql-snow .ql-editor a {
  text-decoration: underline;
}
.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}
.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}
.ql-snow .ql-editor code {
  font-size: 85%;
  padding-bottom: 2px;
  padding-top: 2px;
}
.ql-snow .ql-editor code:before,
.ql-snow .ql-editor code:after {
  content: "\A0";
  letter-spacing: -2px;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}
.ql-snow .ql-editor img {
  max-width: 100%;
}
.ql-snow .ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}
.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}
.ql-snow .ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}
.ql-snow .ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}
.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}
.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px;
}
.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}
.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0px;
}
.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}
.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
  content: attr(data-label);
}
.ql-snow .ql-picker.ql-header {
  width: 98px;
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: 'Normal';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: 'Heading 1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: 'Heading 2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: 'Heading 3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: 'Heading 4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: 'Heading 5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: 'Heading 6';
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 2em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.5em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 1.17em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 1em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  font-size: 0.83em;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  font-size: 0.67em;
}
.ql-snow .ql-picker.ql-font {
  width: 108px;
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Sans Serif';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: 'Serif';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: 'Monospace';
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  font-family: Georgia, Times New Roman, serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  font-family: Monaco, Courier New, monospace;
}
.ql-snow .ql-picker.ql-size {
  width: 98px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normal';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: 'Small';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: 'Large';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: 'Huge';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  font-size: 32px;
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
}
.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}
.ql-snow .ql-tooltip::before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}
.ql-snow .ql-tooltip input[type=text] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: 'Edit';
  margin-left: 16px;
  padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Remove';
  margin-left: 8px;
}
.ql-snow .ql-tooltip a {
  line-height: 26px;
}
.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.ql-snow .ql-tooltip.ql-editing input[type=text] {
  display: inline-block;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: 'Save';
  padding-right: 0px;
}
.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Enter link:";
}
.ql-snow .ql-tooltip[data-mode=formula]::before {
  content: "Enter formula:";
}
.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "Enter video:";
}
.ql-snow a {
  color: #06c;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
}

.statuszokTabla tr:nth-child(even) {background: #00000033}
.statuszokTabla tr:nth-child(odd) {background: #00000011 }
.statuszokTabla td{border-right: solid 1px #00000044;padding:2px;}


.surface{
    background-color:#dfe2ef ;
    
    margin-top: 10;
    color: black;
    margin: 10px;
    padding: 10px;


}

.fc-col-header-cell-cushion{
    color: black;
}
.fc-daygrid-day-number{
    color: black;
}

.fc-h-event {background-color: #283465;}

.fc-today-button.fc-button.fc-button-primary{
    background-color: #283465;
    color:#d6c369;
}

.fc-today-button.fc-button.fc-button-primary:disabled{
    background-color: #283465;
    color:#d6c369;
}

.fc-today-button.fc-button.fc-button-primary:disabled{
    opacity:1;
}

.fc-prev-button.fc-button.fc-button-primary{
    background-color: #283465;
}

.fc-next-button.fc-button.fc-button-primary{
    background-color: #283465;
}
.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active{
    background-color: #283465;
}

/* Use overflow:scroll on your container to enable scrolling: */

.projeckt{
    min-width: 100%;
    text-align: left;
    font-weight: normal;
    font-size:12px;
    background-color:white;

    padding: 20px;
    display: contents;
    overflow: scroll;
    min-height:  80vh ;
    min-height:  80vh ;

}



/* Use position: sticky to have it stick to the edge
 * and top, right, or left to choose which edge to stick to: */

.projecktTabla thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
}


/* To have the header in the first column stick to the left: */

.projecktTabla thead th:first-child {
    position:-webkit-sticky ;
    position:sticky ;
    left: 0;
    z-index: 4;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    background-color: white;
    color:black;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla thead th:nth-child(2) {
    position:-webkit-sticky ;
    position:sticky ;
    left:150px;
    z-index: 4;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    background-color: white;
    color:black;
    font-weight: normal;
    font-size:12px;
}
.projecktTabla thead th:nth-child(3) {
    position:-webkit-sticky ;
    position:sticky ;
    left:300px;
    z-index: 4;
    min-width: 150px;
    max-width: 150px;
    background-color: white;
    color:black;
    font-weight: normal;
    font-size:12px;
}


.projecktTabla tbody th:first-child {
    position:-webkit-sticky ;
    position:sticky ;
    left: 0;
    z-index: 2;
    min-width: 150px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla tbody th:nth-child(2) {
    position:-webkit-sticky ;
    position:sticky ;
    left:150px;
    z-index: 2;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla tbody th:nth-child(3) {
    position:-webkit-sticky ;
    position:sticky ;
    left:300px;
    z-index: 2;
    min-width: 150px;
    max-width: 150px;
    font-weight: normal;
    font-size:12px;
}

/* Just to display it nicely: */

.projecktTabla thead th {
    background: #000;
    color: #FFF;
    /* Ensure this stays above the emulated border right in tbody th {}: */
    z-index: 1;
}

.projecktTabla tbody th {
    background: #FFF;
    border-right: 1px solid #CCC;
    /* Browsers tend to drop borders on sticky elements, so we emulate the border-right using a box-shadow to ensure it stays: */
    box-shadow: 1px 0 0 0 #ccc;
}

.projecktTabla table {
    border-collapse: collapse;
}

.projecktTabla td,th {
    padding: 0.5em;
}


.projecktTabla thead {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */

    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.projecktTabla tr td:last-child {
    min-width: 0;
    white-space: nowrap;
}
/* Use overflow:scroll on your container to enable scrolling: */

.projecktJarmuvek{
    min-width: 100%;
    text-align: left;
    font-family: rasa;
    font-size:14px;
    background-color:white;
    
    padding: 20px;
    display: contents;
    overflow: scroll;
    min-height:  80vh ;
    min-height:  80vh ;

}


/* Use position: sticky to have it stick to the edge
 * and top, right, or left to choose which edge to stick to: */

.projecktJarmuvekTable thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
}

.projecktJarmuvekTable tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
}


/* To have the header in the first column stick to the left: */

.projecktJarmuvekTable thead th:first-child {
    position:-webkit-sticky ;
    position:sticky ;
    left: 0;
    z-index: 2;
    min-width: 300px;
    max-width: 300px;
    overflow: hidden;
    background-color: white;
    color: black;
}

.projecktJarmuvekTable thead th:nth-child(2) {
 
}

.projecktJarmuvekTable  tbody th:first-child {
    position:-webkit-sticky ;
    position:sticky ;
    left: 0;
    z-index: 2;
    min-width: 300px;
    max-width: 300px;
    font-size: 1.5vh;
    white-space: nowrap;
    overflow: hidden;
}



/* Just to display it nicely: */

.projecktJarmuvekTable thead th {
    background: #000;
    color: #FFF;
    /* Ensure this stays above the emulated border right in tbody th {}: */
    z-index: 1;
}

.projecktJarmuvekTable tbody th {
    background: #FFF;
    border-right: 1px solid #CCC;
    /* Browsers tend to drop borders on sticky elements, so we emulate the border-right using a box-shadow to ensure it stays: */
    box-shadow: 1px 0 0 0 #ccc;
}

.projecktJarmuvekTable table {
    border-collapse: collapse;
}

.projecktJarmuvekTable td,th {
    padding: 0.5em;
}


.projecktJarmuvekTable thead {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */

    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.projecktJarmuvekTable tr td:last-child {
    min-width: 0;
    white-space: nowrap;
}
/* Use overflow:scroll on your container to enable scrolling: */

.projecktDolgozok{
    min-width: 100%;
    text-align: left;
    font-family: rasa;
    font-size:14px;
    background-color:white;
    
    padding: 20px;
    display: contents;
    overflow: scroll;
    min-height:  80vh ;
    min-height:  80vh ;

}


/* Use position: sticky to have it stick to the edge
 * and top, right, or left to choose which edge to stick to: */

.projecktDolgozokTable thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
}

.projecktDolgozokTable tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
}


/* To have the header in the first column stick to the left: */

.projecktDolgozokTable thead th:first-child {
    position:-webkit-sticky ;
    position:sticky ;
    left: 0;
    z-index: 2;
    min-width: 300px;
    max-width: 300px;
    overflow: hidden;
    background-color: white;
    color: black;
}



.projecktDolgozokTable  tbody th:first-child {
    position:-webkit-sticky ;
    position:sticky ;
    left: 0;
    z-index: 2;
    min-width: 300px;
    max-width: 300px;
    font-size: 1.5vh;
    white-space: nowrap;
    overflow: hidden;
}



/* Just to display it nicely: */

.projecktDolgozokTable thead th {
    background: #000;
    color: #FFF;
    /* Ensure this stays above the emulated border right in tbody th {}: */
    z-index: 1;
}

.projecktDolgozokTable tbody th {
    background: #FFF;
    border-right: 1px solid #CCC;
    /* Browsers tend to drop borders on sticky elements, so we emulate the border-right using a box-shadow to ensure it stays: */
    box-shadow: 1px 0 0 0 #ccc;
}

.projecktDolgozokTable table {
    border-collapse: collapse;
}

.projecktDolgozokTable td,th {
    padding: 0.5em;
}


.projecktDolgozokTable thead {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */

    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.projecktDolgozokTable tr td:last-child {
    min-width: 0;
    white-space: nowrap;
}
.mobileTable{
   background-color: 'red';
   min-height: 80vh;
   max-height: 80vh;
   overflow-y: auto;
}

.mobileTableCell{
    box-shadow:3px 3px 10px #44444433;
    margin:10px;
    padding:20px;
    min-width:400px;
    max-width:400px;
    background-color:#FFFFFF;
    border-radius: 20px;
    
}

.mobileTableFilter{
    
   
    
    
}

.mobileTableCellFooter{
    
}
