.menu{
    margin-top: 100px;
    min-width: 15%;
    height: 100%;

}

.menuCollapsed{
    margin-top: 100px;
    min-width: 3%;
    height: 100%;


}

.contentDark{
    min-width: 85%;
    background-color: #475360;
    overflow-y: scroll;


}
.contentLight{
    min-width: 85%;
    background-color: #ebedf7;
    overflow-y: scroll;


}

.contentMenuCollapsedDark{
    margin-top: 100px;
    min-width: 97%;
    background-color: #475360;
}
.contentMenuCollapsedLight{
    margin-top: 100px;
    min-width: 97%;
    background-color: #ebedf7;
}

.menuItem{
    display: flex;
    align-items: center;


    background-color: #adb3b8 ;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    color: #475360;
    font-size: 14px;
    line-height: 64px;
    cursor:pointer;
}

.menuItem:hover{

    background-color:  #adb3b8;
    color:#ffffff;

}


.fadeOut{
    opacity:1;
    width:0;
    height:0;
    transition: transform 0.2s;
    transform: scale(0);
}

.fadeIn{
    opacity:1;
    width:'100%';
    height:'100%';
    transition: transform 0.2s;
    transform: scale(1);

}

.menuItemTextDark{
    color: #adb3b8;
    margin-left: 10px;
    font-size: 14px;
    line-height: 64px;
    cursor:pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}
.menuItemTextLight{
    color: #555E68;
    margin-left: 10px;
    font-size: 14px;
    line-height: 64px;
    cursor:pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.menuAndContentHeader{
    position: fixed;
    top:50px;
    display: flex;
    width: 100%;
}
.menuHeader{
    min-width: 15%;
    min-height: 50px;
    background-color: #9FD027;
    color:white;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px #00000077;
}

.menuHeaderCollapsed{
    min-width: 3%;
    min-height: 50px;
    background-color: #9FD027;
    color:white;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px #00000077;
}


.menuHeaderText{
    margin-left: 10px;
}

.contentHeader{
    min-width: 85%;
    min-height: 50px;
    box-shadow: 2px 2px 4px #00000077;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:white;
    z-index: 1000;
    position: relative;

}
.contentHeaderMenuCollapsed{
    min-width: 97%;
    min-height: 50px;
    box-shadow: 2px 2px 4px #00000077;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:white;
    z-index: 1000;
    position: relative;

}

.partnerSelect{
    font-size: 16px;
    margin-left: 20px;
    background-color: #ffffff;
    color: #000000aa;
    margin: 10px;
    border: solid 1px #ffffff;
}
