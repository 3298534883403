/* Use overflow:scroll on your container to enable scrolling: */

.projeckt{
    min-width: 100%;
    text-align: left;
    font-weight: normal;
    font-size:12px;
    background-color:white;

    padding: 20px;
    display: contents;
    overflow: scroll;
    min-height:  80vh ;
    min-height:  80vh ;

}



/* Use position: sticky to have it stick to the edge
 * and top, right, or left to choose which edge to stick to: */

.projecktTabla thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
}


/* To have the header in the first column stick to the left: */

.projecktTabla thead th:first-child {
    position:sticky ;
    left: 0;
    z-index: 4;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    background-color: white;
    color:black;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla thead th:nth-child(2) {
    position:sticky ;
    left:150px;
    z-index: 4;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    background-color: white;
    color:black;
    font-weight: normal;
    font-size:12px;
}
.projecktTabla thead th:nth-child(3) {
    position:sticky ;
    left:300px;
    z-index: 4;
    min-width: 150px;
    max-width: 150px;
    background-color: white;
    color:black;
    font-weight: normal;
    font-size:12px;
}


.projecktTabla tbody th:first-child {
    position:sticky ;
    left: 0;
    z-index: 2;
    min-width: 150px;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla tbody th:nth-child(2) {
    position:sticky ;
    left:150px;
    z-index: 2;
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
    font-size:12px;
}

.projecktTabla tbody th:nth-child(3) {
    position:sticky ;
    left:300px;
    z-index: 2;
    min-width: 150px;
    max-width: 150px;
    font-weight: normal;
    font-size:12px;
}

/* Just to display it nicely: */

.projecktTabla thead th {
    background: #000;
    color: #FFF;
    /* Ensure this stays above the emulated border right in tbody th {}: */
    z-index: 1;
}

.projecktTabla tbody th {
    background: #FFF;
    border-right: 1px solid #CCC;
    /* Browsers tend to drop borders on sticky elements, so we emulate the border-right using a box-shadow to ensure it stays: */
    box-shadow: 1px 0 0 0 #ccc;
}

.projecktTabla table {
    border-collapse: collapse;
}

.projecktTabla td,th {
    padding: 0.5em;
}


.projecktTabla thead {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */

    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.projecktTabla tr td:last-child {
    min-width: 0;
    white-space: nowrap;
}