.popupFrameLight{
    position: fixed;
    display: flex;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(256,256,256,0.5);
    z-index: 1001;
    overflow-y: auto;
}

.formLight{
    display: flex;
    color:#000000;
    padding: 10px;
    margin: auto;

    width: 760px;
    height: 90%;
    background-color: white;
    display: block;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 8px #000000;
    border-radius: 5px;
    overflow-y: auto;
    white-space: nowrap;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

}
.popupWrapperLight{
    display: flex;
    height: 100%;
    flex-direction: column;
}
.popupHeaderLight{
    text-align: center;
    background-color:white;
    color: #313943;
    font-weight: bold;
    padding: 5px;
    text-transform: uppercase;
}

.popupBodyLight{
    flex:1;
    overflow-y: auto;
    text-align: center;
}


.popupFooterLight{
    text-align: center;
    margin: 20px;
    justify-content: center;
}

.row{
    margin-top: 10px;
}

.dropdown-listLight{
    background:#e9ecef;
    position:absolute;
    display: inline-block;
    left:0px;
    top:30px;
    z-index:1;
    width:100%;
    min-width:fit-content;
    text-align: left;
    margin:0px;
    border: solid 1px #e9ecef;
    -webkit-box-shadow: 0px 6px 8px 3px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 6px 8px 3px rgba(0,0,0,0.18);
    box-shadow: 0px 6px 8px 3px rgba(0,0,0,0.18);
}

.dropdown-list-itemLight{
    cursor:pointer;
    width:100%;
    text-align: left;
    padding:5px;
    border-bottom: solid 1px #cccccc;
    z-index: 2;
}

.thumbnailLight{
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 5px; /* Some padding */
    width: 365px; /* Set a small width */
}

/* Add a hover effect (blue shadow) */
.thumbnailLight:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

button, input, optgroup, select,number, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px #31394330;
   
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dialogHeaderLight{
    text-align: center;
    color: #313943;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.componentLight{
    background-color: #FFFFFF;
    color:#25323b;
}

.contentLight{
    background-color: #FFFFFF;
    padding-top: 15px;
    overflow: hidden ;
    max-width: 99%;
}

.contentHeaderLight{
    background-color: #FFFFFF;
}

