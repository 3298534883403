.dateContainerDark{
    color:#9ba7b4;
    display: flex;
    outline: none;
    
    font-size:12px;
    border-bottom: solid 1px #ddd0d00d;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #25323b;

}
.dateContainerFocusedDark{
    color:#9ba7b4;
    display: flex;
    border-bottom: solid 1px #ddd0d00d;;
    outline: none;
    font-size:12px;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #25323b;
}
.dateContainerLight{
    
    display: flex;
    outline: none;
    font-size:12px;
  
    border-bottom: solid 1px black;
    border-radius: 3px;
    
    box-sizing: border-box;
    background-color: #FFFFFF;

}
.dateContainerFocusedLight{
    display: flex;
    border-bottom: solid 1px black;
    outline: none;
    font-size:12px;
  
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    
}

.dateInput{
    outline: none;
    font-size:12px;
    border: none;
    padding: 6px 6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    
}

.dateInput:focus{
    outline: none;
    border: none;
    height: 100%;

        
}

.dateDot{
    color: red;
    font-size: 20px;
    max-height: 12px;
    margin-bottom: 10px;
    border:'solid 1px red';
    background: red;
    
}

.yearInput{
    outline: none;
    border: none;
    padding-left: 6px;
    padding-right: 0px;
    padding-top:6px;
    padding-bottom:6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    width:5ch;
    font-size: 1em;

}
.monthInput{
    outline: none;
    
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-top:6px;
    padding-bottom:6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    width:2ch;
    font-size: 1em;
}

.dayInput{
    outline: none;
    

    border: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-top:6px;
    padding-bottom:6px;
    margin: 1px 0;
    box-sizing: border-box;
    background-color: transparent;
    font-weight: bold;
    width:2ch;
    font-size: 1em;
}
