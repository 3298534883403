.loginWindow{
    background-color: white; 
    min-height: 100%; 
    min-width: 100%; 
    position: absolute; 
    display: flex;
    background-color: #FFFFFF;
}

.loginBox{
    margin: auto;
    width: 400px;
    height: 560px;
    background-color: white;
    display: block;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 8px #00000044;
    padding: 10px;
    
}

.loginHeader{
    background-color: #FFFFFF;
    color:black;
    width: 100%;
    min-height: 30px;
    display: block;
    margin: auto;
    display: flex;
    width: 100%; 
    text-align: center; 
    justify-content: center;
    margin-top: 0px;
}

.logoStyle{
    max-width: 60%;
    text-align: center;
    margin:auto;
}

.loginHeaderText{
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 12px;
    line-height: 18px;
    font-family: Roboto, sans-serif;
    font-size-adjust: none;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-variant-alternates: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-language-override: normal;
    font-feature-settings: normal;
    font-variation-settings: normal;
    color:#000000aa;
}

.loginRow{
    display: flex ;
    margin-top: 50px;
    justify-content: center;
}

.loginTitle{
    text-align: left;  
    margin-left: 30px;
    min-width:140px;
    color: #FFFFFFaa;
    font-weight: bold;
}

.loginInput{
    display: flex;
    color: inherit;
    min-height: 16px;
    text-align: center;

}

.loginInput{
    display: flex;
    color: inherit;
    min-height: 16px;
    text-align: center;
    border-bottom: #4c8ec4;
}

.loginTextField{
    background-color: transparent;
    border:transparent;
    color: inherit;
    outline: none;
    border-bottom:solid 1px #00000044;
    min-width: 350px;
}

.loginTextField:focus{
    
    border-bottom:solid 1px #4c8ec4 ;
    
}


.loginButton{
    background-color: #4c8ec4;
    color:white;
    padding-left:  50px;
    padding-right:   50px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
    font-size: 16px;
    
    text-align: center;
    cursor: pointer;
    border-color: transparent;
}

.loginButton:hover{
    background-color: #325d80;
    
}

.forgottPassword{
    text-align: center;
    color:#4c8ec4;
    font-size: 13px;
}

.loginSuccess{
    display: none; 
    width: 100%; 
    text-align: center; 
    justify-content: center;
    background-color: white ;
    color: #FF0000;
    font-size: 12px;
    padding: 10px;
    text-shadow: 1px 1px 3px #000000;

}

.loginFail{
    display: flex; 
    width: 100%; 
    text-align: center; 
    justify-content: center;
    background-color: white ;
    color: #FF0000aa;
    font-size: 14px;
    padding: 10px;


}
